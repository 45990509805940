export const SLOT_INTERVAL = {
    WEEKLY: 'weekly',
    MONTHLY: 'monthly'
}

export const DEFAULT_VIEW_PARAMS = { view: SLOT_INTERVAL.WEEKLY, viewoffset: 0}

export const DELIVERY_STATUS = {
	ALL: 'All',
	COMPLETED: 'Completed',
	INCOMPLETE: 'Incomplete',
	EMPTY_WALK: 'Empty Walk'
}

export const ALL_PUBPLICATION = 'ALL PUBPLICATIONS'
export const ALL_JOBS = 'All JOBS'
