import { AUTH_UNKNOWN, CUSTOM_OAUTH_PARAMETERS } from '@/const/auth'
import { ROUTES } from '@/const/route'
import { reportError, setTelemetryUser } from '@/services/utils/telemetry'
import { useMainStore } from '@/stores/main'
import {
  GoogleAuthProvider,
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signInWithRedirect,
  signOut
} from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { reactive } from 'vue'
import app from './init'

const auth = getAuth(app)
const functions = getFunctions(app, 'australia-southeast1')

const authState = reactive({
  auth,
  authenticated: AUTH_UNKNOWN,
  loading: true
})

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsub = auth.onAuthStateChanged((user) => {
      authState.loading = authState.loading ? !authState.loading : authState.loading

      if (user) {
        setTelemetryUser({
          email: user.email,
          id: user.uid,
          name: user.displayName
        })

        const mainStore = useMainStore()
        mainStore.setUser({
          email: user.email,
          name: user.displayName ?? user.email
        })
      }

      unsub()
      resolve(user)
    }, reject)
  })
}

const signIn = async () => {
  const provider = new GoogleAuthProvider()
  provider.setCustomParameters(CUSTOM_OAUTH_PARAMETERS)

  try {
    await signInWithRedirect(auth, provider)
  } catch (error) {
    reportError(error)
    throw error
  }
}

const signInWithEmail = (email) => {
  return signInWithEmailLink(auth, email, window.location.href)
}

const requestEmailLink = async (email, redirectUrl) => {
  if (email) {
    const getSignInLink = httpsCallable(functions, 'rubix-getCustomerDashboardSignInLink')
    await getSignInLink({ email, redirectUrl })
    window.localStorage.setItem('emailForSignIn', email)
  }
}

const isEmailSignInLink = (url) => {
  return isSignInWithEmailLink(auth, url)
}

const isUserSignedIn = async () => {
  try {
    return (await getCurrentUser()) !== null
  } catch (error) {
    reportError(error)
    return false
  }
}

const userSignOut = () => {
  signOut(auth)
    .then(() => {
      const mainStore = useMainStore()
      mainStore.$reset()

      authState.authenticated = AUTH_UNKNOWN
      setTelemetryUser(null)
      window.localStorage.clear()
      window.location.replace(`${ROUTES.BASE}${ROUTES.LOGIN}`)
    })
    .catch((error) => reportError(error))
}

export const useAuth = () => {
  return {
    authState,
    getCurrentUser,
    signIn,
    signInWithEmail,
    isEmailSignInLink,
    isUserSignedIn,
    requestEmailLink,
    userSignOut
  }
}
