import { ALL_JOBS, ALL_PUBPLICATION, DEFAULT_VIEW_PARAMS, DELIVERY_STATUS } from "@/const/filter";
import { DELIVERY_SORT_PARAM, SORT_ORDER } from "@/const/sort";

const deliveryFilter = {
    ...DEFAULT_VIEW_PARAMS,
    status: [DELIVERY_STATUS.ALL]
}
export const getDeliveryFilter = () => ({
    ...deliveryFilter,
    contract_no: '',
    job: ALL_PUBPLICATION
});

export const getCmsDeliveryFilter = () => ({
    ...deliveryFilter,
    walkId: "",
    contract_no: '',
    cmsCode: null,
    job: ALL_JOBS,
    status: [],
    sortOrder: SORT_ORDER.ASCENDING,
    sortParam: DELIVERY_SORT_PARAM.ROUND_NAME
  })